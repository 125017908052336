.company_content {
    width: 100%;
    .company_content_img {
        width: 100%;
        height: 600px;
    }
    .home_content_company {
    
        width: 600px;
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        .home_content_major_english {
          font-size: 30px;
          font-weight: bold;
          margin-bottom: 10px;
        }
    
        .home_content_major_title {
          font-size: 25px;
          font-weight: bold;
          margin-bottom: 10px;
          color: #666666;
        }
        .home_content_major_text {
          font-size: 20px;
        }
      }
      .home_content_foot {
        width: 100%;
        height: 300px;
        box-sizing: border-box;
        padding: 50px 80px;
        background: rgba(27, 27, 27, 1);
        display: flex;
        justify-content: space-evenly;
        .home_content_foot_address {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // justify-content: center;
          // align-items: center;
          .home_content_foot_address_name {
            font-size: 24px;
            font-weight: bold;
            color: #fff;
            margin-bottom: 30px;
          }
          .home_content_foot_address_address {
            margin-bottom: 10px;
            .home_content_foot_address_address_title {
              font-size: 17px;
              color: #fff;
            }
            .home_content_foot_address_address_content {
              color: #999999;
              font-size: 16px;
            }
          }
        }
        .home_content_foot_company {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
    
          .home_content_foot_company_name {
            font-size: 24px;
            font-weight: bold;
            color: #fff;
            margin-bottom: 30px;
          }
          .home_content_foot_company_name1 {
            color: #999999;
            font-size: 16px;
            margin-bottom: 10px;
            cursor:pointer;
            &:hover {
                color: #fff;
            }
          }
        }
        .home_content_foot_new {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
    
          .home_content_foot_new_name {
            font-size: 24px;
            font-weight: bold;
            color: #fff;
            margin-bottom: 30px;
          }
          .home_content_foot_new_name1 {
            color: #999999;
            font-size: 16px;
            margin-bottom: 10px;
            cursor:pointer;
            &:hover {
                color: #fff;
            }
          }
        }
        .home_content_foot_business {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
    
          .home_content_foot_business_name {
            font-size: 24px;
            font-weight: bold;
            color: #fff;
            margin-bottom: 30px;
            text-align: center;
          }
          .home_content_foot_business_name1 {
            color: #999999;
            font-size: 16px;
            margin-bottom: 10px;
            text-align: center;
            cursor:pointer;
            &:hover {
                color: #fff;
            }
          }
        }
      }
}
@root-entry-name: default;