@import "~@/style/vars.less";

.@{layout} {
    height: 100vh;
    display: flex;
    flex-direction: column;

    .@{layout}-sider {
        margin-top: @margin-1;
        box-shadow: 1px 1px 5px #d9d9d9;
    }

    .@{layout}-header {
        height: 150px;
        min-height: 46px;
        display: flex;
        align-items: center;
        // background: #4d4c4c;
        // text-indent: 20px;
        justify-content: space-between;
        // border-bottom: 1px solid #d9d9d9;
        // box-shadow: 1px 1px 5px #d9d9d9;
        width: 1400px;
        margin: 0 auto;
        .home_top_img {
            width: 150px;
            height: 100%;
            // background: red;
        }
        .home_top_tap {
            display: flex;
            height: 100%;
            .home_top_tap_item {
                height: 100%;
                display: flex;
                align-items: center;
                padding: 0 15px;
                font-size: 26px;
                font-weight: bold;
                cursor:pointer;;
                color: #000;
                &:hover {
                    color: #1b418b;
                }
                &:active {
                    color: #1b418b; 
                }
            }
        }
        .home_top_login {
            width: 150px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .home_top_login_img {
                width: 100px;
                height: 100px;
               
            }
            .home_top_login_name {
                width: 100px;
                margin-top: 10px;
                font-size: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
        }

    }

    .@{layout}-content {
        flex: 1;
        display: flex;
        flex-direction: row;
        overflow: hidden;
    }

    .@{layout}-main {
        flex: 1;
        // padding: @padding;
        margin-top: @margin-1;
        overflow-y: auto;
    }
}

@root-entry-name: default;